(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/onboarding/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/lb-utils/onboarding/assets/javascripts/utils.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
let deviceSession;
if (!svs.isServer) {
  deviceSession = svs.core.deviceSession;
}
const emptyCounterObject = {
  c: 0
};
const nonWordOrUnderscoreChar = /[^\w_]+/gi;
const buildDeviceSessionKey = (module, baseUrl) => [module, ...baseUrl.split('/').filter(v => Boolean(v)), 'onboarding'].join('_').replace(nonWordOrUnderscoreChar, '');
const asyncStorageBrowserSet = (key, domain, data) => new Promise((resolve, reject) => {
  deviceSession.set(key, domain, data, function (err) {
    if (err) {
      reject(err);
    }
    resolve();
  });
});
const asyncStorageBrowserGet = (key, domain) => new Promise((resolve, reject) => {
  deviceSession.get(key, domain, function (data, err) {
    if (err) {
      reject(err);
    }
    resolve(data);
  });
});
let setDeviceStorage;
let readDeviceStorage;
if (svs.isServer) {
  setDeviceStorage = async (domainKey, key, value, req) => {
    req.session["".concat(domainKey, ".").concat(key)] = value;
  };
  readDeviceStorage = async (domainKey, key, req) => req.session["".concat(domainKey, ".").concat(key)];
} else {
  setDeviceStorage = async (domainKey, key, value) => {
    asyncStorageBrowserSet(key, domainKey, value);
  };
  readDeviceStorage = async (domainKey, key) => {
    let value;
    try {
      value = await asyncStorageBrowserGet(key, domainKey);
    } catch (err) {
      value = undefined;
    }
    return value;
  };
}
const onboardingDomain = (module, baseUrl, req) => {
  const domainKey = buildDeviceSessionKey(module, baseUrl);
  const set = async (key, value) => {
    setDeviceStorage(domainKey, key, value, req);
  };
  const get = async key => await readDeviceStorage(domainKey, key, req);
  return {
    setCount: async (key, count) => {
      await set(key, {
        c: count
      });
    },
    addCount: async key => {
      const counter = (await get(key)) || emptyCounterObject;
      await set(key, _objectSpread(_objectSpread({}, counter), {}, {
        c: counter.c + 1
      }));
    },
    resetCount: async key => {
      await set(key, emptyCounterObject);
    },
    getCount: async key => {
      const counter = (await get(key)) || emptyCounterObject;
      return counter.c;
    }
  };
};
const _exports = {
  buildDeviceSessionKey,
  setDeviceStorage,
  readDeviceStorage,
  onboardingDomain
};
if (svs.isServer) {
  module.exports = _exports;
} else {
  const requestFullscreenMount = component => {
    const {
      ShowFullscreenEvent
    } = svs.components.lbUtils.onboarding.events;
    const onboardingFullscreenEl = document.querySelector('#onboarding_fullscreen');
    if (onboardingFullscreenEl) {
      onboardingFullscreenEl.dispatchEvent(new ShowFullscreenEvent({
        component
      }));
    }
  };
  setGlobal('svs.components.lbUtils.onboarding.utils', _objectSpread(_objectSpread({}, _exports), {}, {
    requestFullscreenMount
  }));
}

 })(window);