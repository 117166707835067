(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/onboarding/assets/javascripts/events/constants.js') >= 0) return;  svs.modules.push('/components/lb-utils/onboarding/assets/javascripts/events/constants.js');
"use strict";

const FullscreenEventType = {
  Show: 'fullscreenOnboardingShow',
  Hide: 'fullscreenOnboardingHide'
};
const TooltipEventType = {
  Add: 'tooltipAdd',
  Created: 'tooltipCreated',
  Delete: 'tooltipDeleted',
  Removed: 'tooltipRemoved'
};
const _exports = {
  FullscreenEventType,
  TooltipEventType
};
setGlobal('svs.components.lbUtils.onboarding.events.constants', _exports);

 })(window);