(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/onboarding/assets/javascripts/events/hide-fullscreen-event.js') >= 0) return;  svs.modules.push('/components/lb-utils/onboarding/assets/javascripts/events/hide-fullscreen-event.js');
"use strict";

const {
  BaseFullscreenEvent
} = svs.components.lbUtils.onboarding.events;
const {
  FullscreenEventType
} = svs.components.lbUtils.onboarding.events.constants;
class HideFullscreenEvent extends BaseFullscreenEvent {
  constructor(props) {
    super(FullscreenEventType.Hide, props);
  }
}

setGlobal('svs.components.lbUtils.onboarding.events.HideFullscreenEvent', HideFullscreenEvent);

 })(window);