(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/onboarding/assets/javascripts/events/event-handler.js') >= 0) return;  svs.modules.push('/components/lb-utils/onboarding/assets/javascripts/events/event-handler.js');
"use strict";

const throwNoElementError = selector => {
  throw new Error("Failed to find node for \"".concat(selector, "\""));
};
const customEventElement = selector => {
  const eventEl = document.querySelector(selector);
  if (!eventEl) {
    return {
      dispatchEvent: () => {
        throwNoElementError(selector);
      },
      addEventListener: () => {
        throwNoElementError(selector);
      },
      removeEventListener: () => {
        throwNoElementError(selector);
      }
    };
  }
  return {
    dispatchEvent: event => eventEl.dispatchEvent(event),
    addEventListener: (type, callbackFn) => eventEl.addEventListener(type, callbackFn),
    removeEventListener: type => eventEl.removeEventListener(type)
  };
};
const createToolTipEventHandler = () => customEventElement('#onboarding_content');
const createFullscreenEventHandler = () => customEventElement('#onboarding_fullscreen');
setGlobal('svs.components.lbUtils.onboarding.events.eventHandlers', {
  createToolTipEventHandler,
  createFullscreenEventHandler
});

 })(window);