(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/onboarding/assets/javascripts/events/show-fullscreen-event.js') >= 0) return;  svs.modules.push('/components/lb-utils/onboarding/assets/javascripts/events/show-fullscreen-event.js');
"use strict";

const {
  VerifyType
} = svs.components.lbUtils.verify.constants;
const {
  verifyObject
} = svs.components.lbUtils.verify;
const {
  BaseFullscreenEvent
} = svs.components.lbUtils.onboarding.events;
const {
  FullscreenEventType
} = svs.components.lbUtils.onboarding.events.constants;
class ShowFullscreenEvent extends BaseFullscreenEvent {
  constructor(props) {
    verifyObject(props).schema({
      component: VerifyType.Object
    });
    super(FullscreenEventType.Show, props);
  }
}

setGlobal('svs.components.lbUtils.onboarding.events.ShowFullscreenEvent', ShowFullscreenEvent);

 })(window);