(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/onboarding/assets/javascripts/events/created-tooltip-event.js') >= 0) return;  svs.modules.push('/components/lb-utils/onboarding/assets/javascripts/events/created-tooltip-event.js');
"use strict";

const {
  VerifyType
} = svs.components.lbUtils.verify.constants;
const {
  verifyObject
} = svs.components.lbUtils.verify;
const {
  BaseTooltipEvent
} = svs.components.lbUtils.onboarding.events;
const {
  TooltipEventType
} = svs.components.lbUtils.onboarding.events.constants;
class CreatedTooltipEvent extends BaseTooltipEvent {
  constructor(props) {
    verifyObject(props).schema({
      tooltip: VerifyType.Element
    });
    super(TooltipEventType.Created, props);
  }
}

setGlobal('svs.components.lbUtils.onboarding.events.CreatedTooltipEvent', CreatedTooltipEvent);

 })(window);